import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, Avatar, Typography } from '@material-ui/core';
import { ExtLink } from 'components';
import { SitePage } from 'components/layout';
import monash_logo from '../assets/monash-logo.png';
import marie from '../assets/marie.png';
import mairead from '../assets/mairead.png';
import pu_logo from '../assets/pu-logo.png';
import action_lab from '../assets/action-lab.png';
import research_summary from '../assets/PiP_research_summary.pdf';
import { Heading } from '../styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    avatarBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    descriptionBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    orderFirst: {
      order: 2,
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    orderSecond: {
      order: 1,
      [theme.breakpoints.down('xs')]: {
        order: 2,
        margin: theme.spacing(2, 0),
      },
    },
    orderFifth: {
      order: 6,
      [theme.breakpoints.down('xs')]: {
        order: 5,
      },
    },
    orderSixth: {
      order: 5,
      [theme.breakpoints.down('xs')]: {
        order: 6,
      },
    },
    avatar: {
      width: 200,
      height: 200,
    },
    logo: {
      width: '350px',
      height: '110px',
      [theme.breakpoints.down('md')]: {
        width: '250px',
        height: '78px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '64px',
      },
    },
  }),
);

export default function index() {
  const classes = useStyles();

  return (
    <SitePage>
      <Container className={classes.root}>
        <Box mt={2} mb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Heading className={classes.heading}>
                The <em>PiP</em> Story
              </Heading>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                <em>Partners in Parenting</em> (PiP) aims to bridge the gap between complex research evidence and
                practical resources for parents.
              </Typography>
              <Typography variant="body1" paragraph>
                We know that parents play an important role in protecting their child’s mental wellbeing. However,
                despite extensive research supporting the role parents can play in reducing their teen’s risk of mental
                health problems, until recently this information was not easily accessible to parents.
              </Typography>
              <Typography variant="body1" paragraph>
                PiP brings together decades of high-quality research evidence and the consensus of international experts
                on parenting and youth mental health. Parents can access this information via an interactive, online
                program, designed to build skills and confidence in ways that may protect their teen from depression and
                anxiety disorders.
              </Typography>
              <Typography variant="body1" paragraph>
                To date, we’ve conducted three large research trials with over 1500 Australian parents. Our results show
                that PiP is effective, with benefits for both parents and teens.
              </Typography>

              <Typography variant="subtitle1">
                <strong>Responding to the pandemic: PiP+</strong>
              </Typography>
              <Typography variant="body1" paragraph>
                The COVID-19 pandemic has brought with it many new challenges for parents and families. To better
                support parents through this time, we’ve enhanced PiP by adding:
              </Typography>

              <Typography component="span">
                <ul>
                  <li>New expert-endorsed content about parenting during the pandemic</li>
                  <li>
                    Access to an online peer support group, for PiP parents to connect with and support each other. The
                    group is facilitated and moderated by members of the PiP team.
                  </li>
                </ul>
              </Typography>

              <Typography>
                If you’d like to read more about the research behind PiP, click{' '}
                <ExtLink href={research_summary}>here</ExtLink> for a summary of published papers, or get in touch with
                us at <ExtLink href="mailto:med-pip-plus@monash.edu">med-pip-plus@monash.edu</ExtLink>.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Heading className={classes.heading}>
                The people behind <em>PiP</em>
              </Heading>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.orderSecond} container alignContent="center">
              <Box className={classes.avatarBox}>
                <img src={monash_logo} width="70%" height="100%" />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.orderFirst}>
              <Box>
                <Typography variant="body1" paragraph>
                  We are a team of researchers at Monash University, Melbourne, Australia. We are passionate about
                  supporting parents and improving the mental wellbeing of young people.
                </Typography>
                <Typography variant="body1" paragraph>
                  Our latest program, PiP+, is made possible through a partnership between the{' '}
                  <strong>Parenting and Youth Mental Health Group</strong>, Turner Institute for Brain and Mental
                  Health, and <strong>Action Lab</strong>, Faculty of Information Technology. Together, we have
                  redesigned PiP to improve parents’ experience and support while completing PiP.
                </Typography>
                <Typography variant="body1" paragraph>
                  We’ve also partnered with <ExtLink href="https://preventionunited.org.au/">Prevention United</ExtLink>{' '}
                  to deliver the program to families across Australia.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 3 }}>
              <Box>
                <Typography variant="h5">Dr Marie Yap</Typography>
                <Typography variant="body1" paragraph>
                  Marie is an Associate Professor, Psychologist and Head of the Parenting and Youth Mental Health Group
                  at Monash University. Marie has been recognised as a leading international expert in parent-child
                  relationships.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} style={{ order: 4 }}>
              <Box className={classes.avatarBox}>
                <Avatar src={marie} className={classes.avatar} />
                <Typography variant="h6">A/Prof Marie Yap</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Lead Researcher & Founder of Partners in Parenting{' '}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.orderSixth}>
              <Box className={classes.avatarBox}>
                <Avatar src={mairead} className={classes.avatar} />
                <Typography variant="h6">Dr Mairead Cardamone-Breen</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Development and Evaluation
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} container alignItems="center" className={classes.orderFifth}>
              <Box>
                <Typography variant="h5">Dr Mairead Cardamone-Breen</Typography>
                <Typography variant="body1" paragraph>
                  Mairead is a Psychologist and Research Fellow in the Parenting and Youth Mental Health Research Group
                  at Monash University. Mairead has been instrumental in the development and evaluation of the Partners
                  in Parenting program.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 7 }}>
              <Box>
                <Typography variant="h5">Action Lab</Typography>
                <Typography variant="body1" paragraph>
                  Action Lab is a multidisciplinary team of impact-focused researchers in the department of
                  Human-Centred Computing at Monash University, Australia. They recently joined the PiP team to
                  co-design and develop PiP+ (the new and updated version of PiP), with the latest technology and their
                  domain expertise in computer science, human-centred design methods, interaction design and digital
                  mental health.
                </Typography>
                <Typography variant="body1" paragraph>
                  The Action Lab project team is led by Professor Patrick Olivier and includes a highly energetic and
                  creative team of researchers and research engineers: Dr Tom Bartindale, Peter Chen, Arie Hendrikse, Dr
                  Roisin McNaney, Joshua Seguin, Dr Ling Wu, and Dr Jue (Grace) Xie.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} style={{ order: 8 }} container alignContent="center">
              <Box className={classes.avatarBox}>
                <img src={action_lab} width="50%" height="100%" />
              </Box>
            </Grid>
          </Grid>

          <Box mt={2} mb={4}>
            <Box mb={4}>
              <Heading className={classes.heading}>Other PiP+ Investigators</Heading>
            </Box>
            <Typography variant="body1" paragraph>
              The PiP+ program and research project are part of a collaboration involving: Emeritus Professor Tony Jorm
              (University of Melbourne); Dr Stephen Carbone (Prevention United); Associate Professor Sarah Whittle
              (University of Melbourne); Dr Orli Schwartz (University of Melbourne); Professor Nicholas Allen
              (University of Oregon); Gail McHardy (Parents Victoria); Associate Professor Glenn Melvin (Deakin
              University); Professor Andrew Thompson (University of Melbourne, Orygen Youth Health, University of
              Warwick); Dr Catherine Wade (Parenting Research Centre); and Dr Steven Leicester (headspace).
            </Typography>
          </Box>

          <Box mt={2} mb={4}>
            <Box mb={4}>
              <Heading className={classes.heading}>Acknowledgements</Heading>
            </Box>

            <Typography variant="body1" paragraph>
              The PiP+ program was delivered in collaboration with our industry partners{' '}
              <ExtLink href="https://preventionunited.org.au/">Prevention United</ExtLink>, who supported the
              implementation of PiP from 2020 to 2022.
            </Typography>

            <Typography variant="body1" paragraph>
              The <em>Partners in Parenting</em> program is part of a suite of parenting resources from the{' '}
              <ExtLink href="https://www.parentingstrategies.net">
                <em>Parenting Strategies Program</em>
              </ExtLink>
              , which was first established in 2010. Throughout its 10-year history, the program has had many
              contributors and funders, whom we cannot all acknowledge by name here. Nonetheless, special thanks go to
              the following contributors to the <em>Partners in Parenting</em> program: Professor Tony Jorm (University
              of Melbourne), Dr Katherine Lawrence (Monash University), Professor Ron Rapee (Macquarie University),
              Associate Professor Glenn Melvin (Deakin University), Jacqueline Green, Clare Nowell (Monash University),
              and all the staff, students, and volunteers of the Parenting and Youth Mental Health Group from 2014 to
              2021.
            </Typography>

            <Typography variant="body1" paragraph>
              We also thank the parents and young people who have contributed feedback throughout the different stages
              of the PiP journey.
            </Typography>

            <Typography variant="body1" paragraph>
              We acknowledge with thanks the funding which have supported the development and evaluation of the program
              from 2011-2021, including: National Health and Medical Research Council (APP IDs 2005621, 566652, 1061744,
              400001), Australian Rotary Health, Monash University, and Helen Macpherson Smith Trust.
            </Typography>
          </Box>
        </Box>

        <Box mt={2} mb={4}>
          <Typography variant="body1" color="textSecondary" align="center" paragraph>
            This work is licensed under the Creative Commons Attribution-ShareAlike 4.0 International License. To view a
            copy of this license, visit <ExtLink href="https://creativecommons.org/licenses/by-sa/4.0/">here</ExtLink>{' '}
            or send a letter to Creative Commons, PO Box 1866, Mountain View, CA 94042, USA.
          </Typography>
        </Box>
      </Container>
    </SitePage>
  );
}
